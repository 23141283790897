import { TYPES } from '../constants'
const { APP_INIT, APP_SET_USER, APP_SET_HEADER } = TYPES

export const initApp = () => {
    return {
        type: APP_INIT
    }
}

export const setProfileData = user => 
    dispatch => dispatch(setUser(user))

export const setTitle = (title, items, funcBack) => {
    document.title = `LEXY - ${title}`
    return {
        type: APP_SET_HEADER,
        headerTitle: title, 
        headerItems: items,
        headerBack: funcBack
    }
}

const setUser = user => {
    return {
        type: APP_SET_USER,
        user
    }
}

import './assets/css/index.css'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import App from './App';
import store from './stores/store'
import reportWebVitals from './reportWebVitals'

import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.locale('es')

//const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
      <App/>
  </Provider>
);

reportWebVitals()


import './assets/css/app.css'

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom'
import { connect } from "react-redux"
import { isBrowser } from "react-device-detect"

import { ConfigProvider, App } from 'antd'
import esES from 'antd/locale/es_ES'
import loadable from '@loadable/component'

import {authAutoSignIn} from "./actions"
import PrivateRoute from './containers/private_route'

const Login = loadable(() => import("./containers/login"))
const Home = loadable(() => isBrowser ? import("./containers/home") : import("./containers/mobile"))

const AppLexy = ({onAutoSignIn, init}) => {

    const theme = {
        token:{      
            fontSize: 15,      
            colorPrimary: '#311111',
            borderRadius: 5,
            colorLink: '#571e1e',
            fontWeightStrong: 500,
            colorBorderSecondary: '#dadada',
            colorTextPlaceholder: 'rgba(0, 0, 0, 0.55)',
            colorTextQuaternary: 'rgba(0, 0, 0, 0.55)'
        },
        components: {
            Form: {
                itemMarginBottom: 12
            },
            Layout:{
                siderBg: '#311111',
                lightSiderBg: '#311111'
            },
            Card: {
                headerBg: 'rgba(125, 43, 43,0.25)',
                colorBorderSecondary: "rgba(0, 0, 0, 0.25)",
                colorTextDescription: 'rgba(0, 0, 0, 0.85)'
            },
            Menu:{         
                darkItemBg: '#311111',
                darkSubMenuItemBg: '#311111'
            },
            Descriptions: {
                labelBg: 'rgba(125, 43, 43,0.25)'
            },
            Table: {
                headerBg: 'rgba(125, 43, 43,0.25)',
                rowSelectedBg: 'rgba(140, 140, 140,0.3)',
                rowSelectedHoverBg: 'rgba(140, 140, 140,0.5)',
            },
            List: {
                headerBg: 'rgba(125, 43, 43,0.25)'
            }
        }
    }

    useEffect(()=> {
        onAutoSignIn()
    })    

    return(
        <ConfigProvider locale={esES} theme={theme}>
            <App>
                <Router>
                    {init && <Switch>
                        <Route path="/auth/:authType/:id?" component={Login} />
                        <PrivateRoute path="/" component={Home} />
                    </Switch>}
                </Router>
            </App>
        </ConfigProvider>
    )
} 

const mapStateToProps = state => ({
    init: state.ui.init,
})
const mapDispatchToProps = dispatch => ({
    onAutoSignIn: () => dispatch(authAutoSignIn())
})
export default connect(mapStateToProps, mapDispatchToProps)(AppLexy)

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from "react-redux"

const PrivateRoute = ({ component: Component, is_auth, ...rest }) => (
    <Route {...rest} render={props => 
        is_auth ? <Component {...props} /> : <Redirect to={{
            pathname: '/auth/login', 
            state: {from: props.location}
        }}/>
    }/>
)

const mapStateToProps = state => ({
    is_auth: state.auth.authenticate,
})

export default connect(mapStateToProps, null)(PrivateRoute)

import { TYPES } from '../../constants'
const { APP_INIT, APP_SET_USER, APP_SET_HEADER } = TYPES

const initialState = {
    init: false,
    user: {},
    headerTitle: null,
    headerItems: null,
    headerBack: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case APP_INIT:
        return {
            ...state,
            init: true
        }  
    case APP_SET_USER:
        return {
            ...state,
            user: action.user
        }
    case APP_SET_HEADER:
        return {
            ...state,
            headerTitle: action.headerTitle,
            headerItems: action.headerItems,
            headerBack: action.headerBack
        }
    default:
        return state
  }
}

export default reducer
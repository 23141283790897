import axios from 'axios'
import decode from 'jwt-decode'

import { TYPES, VARS } from '../constants'
import {initApp, setProfileData, handleErrors} from './'

const { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, AUTH_SET_TOKEN, AUTH_CLEAN } = TYPES

export const authLogin =  authData => 
    dispatch => 
        new Promise(async (resolve, reject) => {
            try{
                dispatch({type: AUTH_REQUEST})
                const response = await axios.post(`${VARS.AUTH_URL}/auth_token`, {auth: authData})
                const {profile, token_id, refresh_token} = response.data
                dispatch(authSetStorage(profile, token_id, refresh_token))
                dispatch(authSuccess())
                resolve(profile)
            }catch(e){
                //const dataError = e.response ? e.response.data : {message: "Error con el servidor"}
                handleErrors(e)
                reject(e)
                //dispatch(authFailure("LoginError"))
            }
        })
  

export const authGetToken = () => 
    (dispatch, getState) => 
        new Promise(async (resolve, reject) => {
            try{
                const {token, expiryDate} = getState().auth
                if(!token || new Date(expiryDate) <= new Date()){
                    const storageToken = JSON.parse(localStorage.getItem(VARS.TOKEN_KEY))
                    const storageUser = JSON.parse(localStorage.getItem(VARS.USER_INFO))
                    if(!isTokenExpired(storageToken)){
                        const expiryDate = new Date(decode(storageToken).exp * 1000)
                        dispatch(authSetToken(storageToken,expiryDate))
                        dispatch(setProfileData(storageUser))
                        resolve(storageToken)//DONE FROM STORAGE
                    }else{
                        const storageRefresh = JSON.parse(localStorage.getItem(VARS.REFRESH_KEY))
                        if(storageRefresh){          
                            const refresh = {user_id: decode(storageToken).sub, token: storageRefresh}
                            const response = await axios.post(`${VARS.AUTH_URL}/refresh_token`,{refresh})
                            const {profile, token_id, refresh_token} = response.data
                            dispatch(authSetStorage(profile, token_id, refresh_token))
                            dispatch(setProfileData(profile))
                            resolve(token_id)
                        }else{
                            reject("NeedLoginNoStorage")
                        }
                    }
                }else{
                    resolve(token)
                }
            }catch(e){
                console.log(e)
                reject("NeedLoginCatch")
            }
        })
  
export const authSetStorage = (profile, token, refreshToken) => 
    dispatch => {
    //const expiryDate = new Date(decode(token).exp * 1000);
        const exp = Date.now() + 3600
        const expiryDate = new Date(exp)
        localStorage.setItem(VARS.TOKEN_KEY, JSON.stringify(token)) 
        localStorage.setItem(VARS.REFRESH_KEY, JSON.stringify(refreshToken))
        localStorage.setItem(VARS.USER_INFO, JSON.stringify(profile))
        dispatch(authSetToken(token, expiryDate))
    }

export const authLogOut = () => 
    async dispatch => {
        try{
            const refresh_token = JSON.parse(localStorage.getItem(VARS.REFRESH_KEY))
            await axios.delete(`${VARS.AUTH_URL}/auth_token?token=${refresh_token}`)     
            window.location.replace('/auth/login')
        }catch(e){
            console.log(e)
            window.location.replace('/auth/login?error=TokenError')
        }finally{
            localStorage.removeItem(VARS.TOKEN_KEY)
            localStorage.removeItem(VARS.REFRESH_KEY)
            localStorage.removeItem(VARS.USER_INFO)       
            dispatch({type: AUTH_CLEAN})
        }
    }

export const authAutoSignIn = () => 
    async dispatch => { 
        try{
            await dispatch(authGetToken())
            dispatch(authSuccess())
        }catch(e){
            dispatch(authFailure(e))
        }finally{
            dispatch(initApp())
        }
    }

export const isTokenExpired = token => {
    try{
        const decoded = decode(token)
        return (decoded.exp < (Date.now() / 1000))
    }catch (err) {
        return true;
    }
}

export const authSetToken = (token, expiryDate) => ({
    type: AUTH_SET_TOKEN,
    token: token,
    expiryDate: expiryDate
})

const authSuccess = () => ({
    type: AUTH_SUCCESS
})

const authFailure = errorMessage => ({
    type: AUTH_FAILURE,
    message: errorMessage
})